import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';
import { useCurrentUser } from '../useCurrentUser';
import { computed, Ref } from '@nuxtjs/composition-api';

type SubscribeToNotificationsParams = {
  product: number;
  first_name: string;
  surname_prefix?: string;
  surname: string;
  email_address: string;
  telephone?: string;
};

export const usePDPStockNotifications = (): {
  error: Ref<{
    getSubscriptionStatus: any;
    getContainerTranslations: any;
    subscribeToNotifications: any;
  }>;
  getSubscriptionStatus: (
    productId: number,
    email?: string
  ) => Promise<{ is_already_subscribed: boolean }>;
  getContainerTranslations: () => Promise<any>;
  subscribeToNotifications: (
    params: SubscribeToNotificationsParams
  ) => Promise<void>;
} => {
  const context = useVSFContext();
  const { currentUser } = useCurrentUser();
  const containerTranslations = sharedRef(
    null,
    'usePDPStockNotifications-containerTranslations'
  );

  const error = sharedRef(
    {
      getSubscriptionStatus: null,
      getContainerTranslations: null,
      subscribeToNotifications: null
    },
    'usePDPStockNotifications-error'
  );

  const getSubscriptionStatus = async (productId, email) => {
    email = email || currentUser.value?.email;

    if (!email) return false;

    try {
      return await context.$novulo.api.getSubscriptionStatus({
        productId,
        email
      });
    } catch (err) {
      Logger.error('usePDPStockNotifications/getSubscriptionStatus', err);
      error.value.getSubscriptionStatus = err;
    }
  };
  const getContainerTranslations = async () => {
    try {
      if (!containerTranslations.value) {
        containerTranslations.value =
          await context.$novulo.api.getContainerTranslations();
      }
      return containerTranslations.value;
    } catch (err) {
      Logger.error('usePDPStockNotifications/getContainerTranslations', err);
      error.value.getContainerTranslations = err;
    }
  };
  const subscribeToNotifications = async (params) => {
    try {
      await context.$novulo.api.updateSubscriptionStatus({
        ...params,
        type: 1,
        allow_duplicates: false,
        allow_with_stock_available: true
      });
    } catch (err) {
      Logger.error('usePDPStockNotifications/subscribeToNotifications', err);
      error.value.subscribeToNotifications = err;
    }
  };

  return {
    error: computed(() => error.value),
    getSubscriptionStatus,
    getContainerTranslations,
    subscribeToNotifications
  };
};
