import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';
import type { UseDeliveryAddress } from './interfaces';
import { computed } from '@nuxtjs/composition-api';
import { useCheckoutCart } from '../useCheckoutCart';
import type { UserAddress } from '@vue-storefront/novulo-api';
import { useUserAddresses } from '../useUserAddresses';
import { useCheckoutPreferences } from 'src/useCheckoutPreferences';

export const useDeliveryAddress = (): UseDeliveryAddress => {
  const context = useVSFContext();
  const { cartId, getShippingRequests, shippingRequests } = useCheckoutCart();

  const loading = sharedRef(false, 'useDeliveryAddress-loading');
  const error = sharedRef(false, 'useDeliveryAddress-error');

  const selectedDeliveryMethod = sharedRef(
    null,
    'useDeliveryAddress-selectedAddress'
  );
  const selectedDeliveryType = sharedRef(
    null,
    'useDeliveryAddress-selectedDeliveryType'
  );

  const selectedInvoiceAddress = sharedRef(
    null,
    'useDeliveryAddress-selectedInvoiceAddress'
  );

  const deliveryMethodTypes = {
    SHIPPING: 'SHIPPING',
    STORE: 'STORE',
    PICKUP: 'PICKUP'
  };

  const { userAddresses } = useUserAddresses();
  const {
    getPreferredDeliveryAddressId,
    setPreferredDeliveryAddressId,
    setPreferredInvoiceAddressId
  } = useCheckoutPreferences();

  // return preferred shipping / first option on mount
  const getInitialShippingAddress = () => {
    const preferredDeliveryAddressId = getPreferredDeliveryAddressId();

    const preferredDeliveryAddress = preferredDeliveryAddressId
      ? userAddresses.value.find(
          (address: UserAddress) => address.id === preferredDeliveryAddressId
        ) || null
      : null;

    const defaultDeliveryAddress =
      userAddresses.value.find(
        (address: UserAddress) => address.is_default_delivery_address
      ) || null;

    const initialAddress =
      preferredDeliveryAddress ||
      defaultDeliveryAddress ||
      userAddresses.value[0];

    return {
      ...initialAddress,
      type: deliveryMethodTypes.SHIPPING
    };
  };

  const availableDeliveryAddresses = sharedRef(
    {
      [deliveryMethodTypes.SHIPPING]: getInitialShippingAddress(),
      [deliveryMethodTypes.STORE]: undefined,
      [deliveryMethodTypes.PICKUP]: undefined
    },
    'useDeliveryAddress-availableDeliveryAddresses'
  );

  const selectedShippingAddressId = sharedRef(
    null,
    'selectedShippingAddressId-selectedInvoiceAddress'
  );

  const selectDeliveryMethod = async (method, type) => {
    loading.value = true;

    selectedDeliveryMethod.value = method;
    selectedDeliveryType.value = type;

    availableDeliveryAddresses.value[type] = { ...method, type };

    if (selectedDeliveryType.value === deliveryMethodTypes.SHIPPING) {
      if (selectedDeliveryMethod.value.id === selectedShippingAddressId.value)
        return;

      selectedShippingAddressId.value = selectedDeliveryMethod.value.id;
      setPreferredDeliveryAddressId(selectedShippingAddressId.value);
      try {
        await context.$novulo.api.setCheckoutAddress({
          cartId,
          addressId: selectedDeliveryMethod.value.id,
          addressType: 'delivery'
        });

        await getShippingRequests();

        availableDeliveryAddresses.value[deliveryMethodTypes.STORE] = {
          ...shippingRequests.value.stores?.[0],
          type: deliveryMethodTypes.STORE
        };
        availableDeliveryAddresses.value[deliveryMethodTypes.PICKUP] = {
          ...shippingRequests.value.pickUp?.[0],
          type: deliveryMethodTypes.PICKUP
        };
      } catch (e) {
        error.value = e;
        Logger.error('useDeliveryAddress/selectDeliveryMethod', e);
      } finally {
        loading.value = false;
      }
    }
  };

  const selectThankYouDeliveryMethod = (method, type) => {
    selectedDeliveryMethod.value = method;
    selectedDeliveryType.value = type;
  };
  const selectThankYouInvoiceAddress = (address) => {
    selectedInvoiceAddress.value = address;
  };

  const selectInvoiceAddress = async (address: UserAddress) => {
    loading.value = true;

    await context.$novulo.api
      .setCheckoutAddress({
        cartId,
        addressId: address.id,
        addressType: 'invoice'
      })
      .then(() => {
        selectedInvoiceAddress.value = address;
        setPreferredInvoiceAddressId(address.id);
      })
      .catch((e) => {
        error.value = e;
        Logger.error('useDeliveryAddress/selectInvoiceAddress', e);
      })
      .finally(() => (loading.value = false));
  };

  return {
    selectedDeliveryMethod: computed(() => selectedDeliveryMethod.value),
    selectedDeliveryType: computed(() => selectedDeliveryType.value),
    selectedInvoiceAddress: computed(() => selectedInvoiceAddress.value),
    deliveryMethodTypes,
    availableDeliveryAddresses: computed(() => {
      const availableDeliveryAddressesList = [];

      if (availableDeliveryAddresses.value[deliveryMethodTypes.SHIPPING]?.id) {
        availableDeliveryAddressesList.push(
          availableDeliveryAddresses.value[deliveryMethodTypes.SHIPPING]
        );
      }

      if (availableDeliveryAddresses.value[deliveryMethodTypes.STORE]?.id) {
        availableDeliveryAddressesList.push(
          availableDeliveryAddresses.value[deliveryMethodTypes.STORE]
        );
      }

      if (availableDeliveryAddresses.value[deliveryMethodTypes.PICKUP]?.id) {
        availableDeliveryAddressesList.push(
          availableDeliveryAddresses.value[deliveryMethodTypes.PICKUP]
        );
      }

      return availableDeliveryAddressesList;
    }),
    selectDeliveryMethod,
    selectInvoiceAddress,
    selectThankYouDeliveryMethod,
    selectThankYouInvoiceAddress
  };
};
