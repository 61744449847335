import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';
import type { UseCartSummary } from './interfaces';
import { useCheckoutCart } from 'src/useCheckoutCart';
import { computed } from '@nuxtjs/composition-api';

export const useCartSummary = (): UseCartSummary => {
  const context = useVSFContext();
  const { getCart, determineValueAfterGiftcards, cart } = useCheckoutCart();
  const cartSummaryTranslations = sharedRef(
    {},
    'useCartSummary-cartSummaryTranslations'
  );
  const discountResponse = sharedRef(
    {
      state: null,
      message: ''
    },
    'useCartSummary-discountResponse'
  );

  const loading = sharedRef(
    {
      addDiscount: false,
      getHasPin: false,
      removeDiscount: false
    },
    'useCartSummary-loading'
  );
  const addDiscount = async (discountCode, discountPin) => {
    loading.value.addDiscount = true;

    return await context.$novulo.api
      .addDiscount({
        code: discountCode,
        pin: discountPin,
        cart_guid: cart.value.shoppingcart_guid
      })
      .then(async (response) => {
        if (response.success) {
          await determineValueAfterGiftcards();
          void getCart();
        }
        discountResponse.value.state = response.success ? 'success' : 'failed';
        discountResponse.value.message = response.error
          ? ['Code not added as coupon or giftcard:']
          : ['discount_added_successfully'];

        return response.success;
      })
      .catch((e) => {
        Logger.error('useCartSummary/addDiscount', e);

        discountResponse.value.state = 'failed';
        discountResponse.value.message = ['add_discount_server_error'];
      })
      .finally(() => (loading.value.addDiscount = false));
  };

  const getHasPin = async (discountCode) => {
    loading.value.getHasPin = true;

    return context.$novulo.api
      .getHasPin({ gift_card_code: discountCode })
      .catch((e) => Logger.error('useCartSummary/getHasPin', e))
      .finally(() => (loading.value.getHasPin = false));
  };
  const removeDiscount = async (code, type) => {
    loading.value.removeDiscount = true;

    return context.$novulo.api
      .removeDiscount({
        code,
        type,
        cart_guid: cart.value.shoppingcart_guid
      })
      .then(async (response) => {
        if (response.success) {
          await determineValueAfterGiftcards();
          void getCart();
        }
        discountResponse.value.state = response.success ? 'success' : 'failed';
        discountResponse.value.message = response.error?.split('\n')[0] || [
          'discount_removed_successfully'
        ];

        return response.success;
      })
      .catch((e) => {
        Logger.error('useCartSummary/removeDiscount', e);
        discountResponse.value.state = 'failed';
        discountResponse.value.message = ['remove_discount_server_error'];
      })
      .finally(() => (loading.value.removeDiscount = false));
  };

  const getCartSummaryTranslations = async () => {
    if (!Object.keys(cartSummaryTranslations.value).length)
      cartSummaryTranslations.value = await context.$novulo.api
        .getCartSummaryTranslations()
        .catch((e) =>
          Logger.error('useCartSummary/getCartSummaryTranslations', e)
        );
  };

  return {
    cartSummaryTranslations,
    discountResponse,
    loading: computed(() => loading.value),
    addDiscount,
    getHasPin,
    removeDiscount,
    getCartSummaryTranslations
  };
};
