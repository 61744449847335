export const useBloomreachEvents = () => {
  const registerEvent = (eventName, eventDetails) => {
    (window as any).exponea?.track?.(eventName, eventDetails);
  };

  const identify = (email) => {
    const customerCookie = (window as any).exponea?.configuration?.customer
      ?.cookie;
    (window as any).exponea?.identify?.({
      email_id: email,
      cookie: customerCookie
    });
  };

  return { registerEvent, identify };
};
