import { computed } from '@nuxtjs/composition-api';
import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';
import type { CheckoutSuccess } from '@vue-storefront/novulo-api';

export const useCheckoutSuccess = () => {
  const context = useVSFContext();
  const checkoutSuccessResult = sharedRef(null, 'useCheckoutSuccess');
  const loading = sharedRef(false, 'useCheckoutSuccess-loading');
  const error = sharedRef(
    {
      getCheckoutSuccess: null
    },
    'useCheckoutSuccess-error'
  );

  const getCheckoutSuccess = async (order_id) => {
    loading.value = true;
    const response: CheckoutSuccess = await context.$novulo.api
      .getCheckoutSuccess(order_id)
      .catch((e) => {
        error.value.getCheckoutSuccess = e;
        Logger.error('useCheckoutSuccess-getCheckoutSuccess', e);
      })
      .finally(() => {
        loading.value = false;
      });

    checkoutSuccessResult.value = response;
  };

  return {
    getCheckoutSuccess,
    checkoutSuccessResult: computed(() => checkoutSuccessResult.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value)
  };
};
