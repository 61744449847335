import { computed } from '@nuxtjs/composition-api';
import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';
import { GetPLPResponse } from '@vue-storefront/novulo-api';

export const usePLP = () => {
  const context = useVSFContext();
  const nextResult = sharedRef([], 'usePLP-nextResult');
  const previousResult = sharedRef([], 'usePLP-previousResult');
  const loading = sharedRef(false, 'usePLP-loading');
  const error = sharedRef(
    {
      getPLPProducts: null
    },
    'usePLP-error'
  );
  const cancelKey = sharedRef(Math.random(), 'usePLP-cancelKey');

  const getPLP = async (data): Promise<GetPLPResponse> => {
    Logger.debug('usePLP custom componsable');
    try {
      loading.value = true;

      // remove page from path as it is passed as a param by itself
      data.params = data.params.replace(/&page=\d+/, '');

      data.cancelKey = cancelKey.value;

      const response = await context.$novulo.api.getPLP(data);
      // axios doesn't throw errors for a bad response so we return {isCanceled: true} in the catch block of getPLP
      if (response.isCanceled) return;

      if (data.resultList === 'previous') {
        previousResult.value = response;
      } else {
        nextResult.value = response;
      }
    } catch (err) {
      error.value.getPLPProducts = err;
      Logger.error('usePLP', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    getPLP,
    plpNextPageResult: computed(() => nextResult.value),
    plpPreviousPageResult: computed(() => previousResult.value),
    plpLoading: computed(() => loading.value),
    plpError: computed(() => error.value)
  };
};
