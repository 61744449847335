import { computed } from '@nuxtjs/composition-api';
import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';

export const useCheckoutRedirects = () => {
  const context = useVSFContext();
  const redirects = sharedRef(null, 'useCheckoutRedirects');
  const error = sharedRef(
    {
      getCheckoutRedirects: null
    },
    'useCheckoutRedirects-error'
  );

  const getCheckoutRedirects = async () => {
    if (redirects.value?.length) return;

    Logger.debug('useCheckoutRedirects custom componsable');

    try {
      redirects.value = await context.$novulo.api.getCheckoutRedirects();
    } catch (err) {
      error.value.getCheckoutRedirects = err;
      Logger.error('useCheckoutRedirects', err);
    }
  };

  getCheckoutRedirects();

  return {
    redirects: computed(() => redirects.value),
    error: computed(() => error.value)
  };
};
