import { sharedRef, useVSFContext } from '@vue-storefront/core';
import { computed } from '@nuxtjs/composition-api';

type CartPageEssentials = {
  carePlanModal: string;
  notInStockNotification: string;
  returnEquipmentCard: string;
};

export const useCartPageEssentials = () => {
  const data = sharedRef<CartPageEssentials>(
    null,
    'useCartPageEssentials-data'
  );
  const error = sharedRef(null, 'useCartPageEssentials-error');
  const isLoading = sharedRef(false, 'useCartPageEssentials-isLoading');

  const context = useVSFContext();

  const getCartPageEssentials = async () => {
    try {
      error.value = null;
      isLoading.value = true;
      data.value = await context.$novulo.api.getCartPageEssentials();
    } catch (err) {
      error.value = err;
    } finally {
      isLoading.value = false;
    }
  };

  return {
    data: computed(() => data.value),
    error: computed(() => error.value),
    isLoading: computed(() => isLoading.value),
    getCartPageEssentials
  };
};
