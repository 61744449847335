import { useVSFContext } from '@vue-storefront/core';

type AvailabilityType = {
  address: string;
  has_inventory: boolean;
  id: number;
  warehouse_name: string;
};

export const usePDPExtras = (): {
  getPDPStock: (productId: number) => Promise<AvailabilityType[]>;
  getPDPDeliveryContainer: (containerType: string) => Promise<any>;
} => {
  const context = useVSFContext();

  const getPDPStock = (productId: number) => {
    return context.$novulo.api.getPDPStock(productId);
  };

  const getPDPDeliveryContainer = (containerType: string) => {
    return context.$novulo.api.getPDPDeliveryContainer(containerType);
  };

  return {
    getPDPStock,
    getPDPDeliveryContainer
  };
};
