import { useVSFContext, sharedRef } from '@vue-storefront/core';

export const useCountries = () => {
  const context = useVSFContext();

  const countriesResult = sharedRef(null, 'useCountries');
  const loadingCountries = sharedRef(false, 'useCountries-loading');
  const countriesError = sharedRef(
    {
      useCountries: null
    },
    'useCountries-error'
  );

  const getCountries = async () => {
    try {
      loadingCountries.value = true;

      countriesResult.value = await context.$novulo.api.getCountries();
    } catch (e) {
      countriesError.value.useCountries = e;
    } finally {
      loadingCountries.value = false;
    }
  };

  return {
    getCountries,
    countriesResult,
    loadingCountries,
    countriesError
  };
};
