import { useVSFContext, sharedRef, Logger } from '@vue-storefront/core';
import type { UseAddressForm } from './interfaces';
import { useCountries } from 'src/useCountries';
import { computed } from '@nuxtjs/composition-api';

const defaultModel = {
  id: null,
  personal: true,
  email: '',
  password: '',
  passwordConfirmation: '',
  organisation: null,
  first_name: '',
  prefix_surname: '',
  surname: '',
  country: {
    id: null,
    iso_code: '',
    name: ''
  },
  address_finder: '',
  postal_code: '',
  house_number: null,
  house_number_appendix: '',
  street: '',
  city: '',
  phone_number: '',
  vat_number: '',
  newsletter_opt_in: false,
  newsletter_opt_in_soft: true,
  additional_address_line: '',
  address_supplement: '',
  is_default_delivery_address: false,
  is_default_invoice_address: false,
  create_new_user: false
};

const separateHouseNumberAppendix = (houseData) => {
  const firstNonDigit = houseData.search(/[^\d]/);
  if (firstNonDigit < 0) return [houseData, ''];
  const num = houseData.slice(0, firstNonDigit);
  const appendix = houseData.slice(firstNonDigit);
  return [num, appendix];
};

export const useAddressForm = (): UseAddressForm => {
  const context = useVSFContext();

  const { countriesResult } = useCountries();

  const model = sharedRef(
    JSON.parse(JSON.stringify(defaultModel)),
    'useAddressForm-model'
  );

  const hasAddressFinder = computed(() => {
    const selectedCountry = countriesResult.value?.find(
      (country) => country?.iso_code === model.value?.country?.iso_code
    );
    if (!selectedCountry) return false;
    return selectedCountry.use_address_checker;
  });

  const fetchifyAddresses = sharedRef([], 'useAddressForm-fetchifyAddresses');

  const loading = sharedRef(false, 'useAddressForm-loading');

  const fetchifySelectedAddressMissingFields = sharedRef(
    {},
    'useAddressForm-fetchifySelectedAddressMissingFields'
  );

  const findFetchifyAddresses = async (search, id) => {
    const results = await context.$novulo.api.findFetchifyAddresses({
      search,
      id,
      countryCode: model.value.country.iso_code
    });

    fetchifyAddresses.value = results ?? [];
  };

  const selectFetchifyAddress = async (id) => {
    const result = await context.$novulo.api.selectFetchifyAddress({
      id,
      countryCode: model.value.country.iso_code
    });

    const [houseNumber, appendix] = separateHouseNumberAppendix(
      result.building_number
    );

    model.value = {
      ...model.value,
      postal_code: result.postal_code,
      house_number: houseNumber,
      house_number_appendix: appendix + result.building_name,
      street:
        `${result.street_prefix} ${result.street_name} ${result.street_suffix}`.trim(),
      city: result.locality
    };

    fetchifySelectedAddressMissingFields.value = {
      postal_code: !model.value.postal_code,
      house_number: !model.value.house_number,
      house_number_appendix: !model.value.house_number_appendix,
      street: !model.value.street,
      city: !model.value.city
    };

    return result;
  };

  const validatePostalCode = async (): Promise<void> => {
    loading.value = true;

    const { response } = await context.$novulo.api
      .validatePostalCode({
        postal_code: model.value.postal_code,
        country_iso_code: model.value.country.iso_code
      })
      .catch((e) => Logger.error('useAddressForm/validatePostalCode', e))
      .finally(() => (loading.value = false));

    model.value.postal_code = response.postal_code_formatted || '';
  };

  const postalCodeToAddress = async (): Promise<void> => {
    loading.value = true;

    const params = {
      postal_code: model.value.postal_code,
      house_number: parseInt(model.value.house_number),
      country_iso_code: model.value.country.iso_code
    };

    const { response } = await context.$novulo.api
      .postalCodeToAddress(params)
      .catch((e) => Logger.error('useAddressForm/postalCodeToAddress', e))
      .finally(() => (loading.value = false));

    model.value.street = response.street || '';
    model.value.city = response.city || '';
  };

  const resetModel = () => {
    model.value = JSON.parse(JSON.stringify(defaultModel));
    fetchifySelectedAddressMissingFields.value = {};
  };

  return {
    model,
    fetchifyAddresses,
    hasAddressFinder,
    fetchifySelectedAddressMissingFields,
    findFetchifyAddresses,
    selectFetchifyAddress,
    validatePostalCode,
    postalCodeToAddress,
    resetModel
  };
};
