import { computed, ComputedRef } from '@nuxtjs/composition-api';
import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';

export const usePDP = (
  productSlug: string
): {
  getPDP: (spliId: number, productId: number) => Promise<void>;
  pdpResult: ComputedRef<any>;
  loading: ComputedRef<any>;
  getPDPError: ComputedRef<any>;
  relatedAcc: ComputedRef<any>;
  relatedAccError: ComputedRef<any>;
  hasLoadedRelatedAcc: ComputedRef<any>;
  specifications: ComputedRef<any>;
  specificationsError: ComputedRef<any>;
  hasLoadedSpecifications: ComputedRef<any>;
} => {
  const context = useVSFContext();
  const pdpResult = sharedRef(null, `usePDP-${productSlug}`);
  const loading = sharedRef(false, `usePDP-loading-${productSlug}`);
  const error = sharedRef(
    {
      getPDP: null
    },
    `usePDP-error-${productSlug}`
  );

  const specifications = sharedRef(
    null,
    `usePDP-specifications-${productSlug}`
  );
  const specificationsError = sharedRef(
    null,
    `usePDP-specifications-error-${productSlug}`
  );
  const hasLoadedSpecifications = sharedRef(
    false,
    `usePDP-has-loaded-specifications-${productSlug}`
  );

  const relatedAcc = sharedRef(null, `usePDP-related-accesorie-${productSlug}`);
  const relatedAccError = sharedRef(
    null,
    `usePDP-related-accesorie-error-${productSlug}`
  );
  const hasLoadedRelatedAcc = sharedRef(
    false,
    `usePDP-has-loaded-related-accesorie-${productSlug}`
  );

  const getPDP = async (spliId: number, productId: number) => {
    Logger.debug('usePDP custom componsable', productSlug);
    try {
      loading.value = true;
      pdpResult.value = await context.$novulo.api.getPDP(spliId, productId);
      relatedAcc.value = pdpResult.value.related_products;
      hasLoadedRelatedAcc.value = true;
      specifications.value = pdpResult.value.specifications;
      hasLoadedSpecifications.value = true;
    } catch (err) {
      error.value.getPDP = err;
      Logger.error(`usePDP/${productSlug}`, err);
    } finally {
      loading.value = false;
    }
  };

  return {
    getPDP,
    pdpResult: computed(() => pdpResult.value),
    loading: computed(() => loading.value),
    getPDPError: computed(() => error.value),
    relatedAcc: computed(() => relatedAcc.value),
    relatedAccError: computed(() => relatedAccError.value),
    hasLoadedRelatedAcc: computed(() => hasLoadedRelatedAcc.value),
    specifications: computed(() => specifications.value),
    specificationsError: computed(() => specificationsError.value),
    hasLoadedSpecifications: computed(() => hasLoadedSpecifications.value)
  };
};
