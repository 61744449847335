import { Logger, useVSFContext } from '@vue-storefront/core';
import type { IsEmailRegisteredResponse } from '@vue-storefront/novulo-api';

const CART_COOKIE = 'n_shopping_cart';

export const useAuthentication = () => {
  const context = useVSFContext();

  const getIsEmailRegistered = async (
    email: string
  ): Promise<IsEmailRegisteredResponse> => {
    return context.$novulo.api.getIsEmailRegistered(email);
  };

  const login = async ({ email, password }) => {
    try {
      const cartId = context.$cookies.get(CART_COOKIE) || null;
      const response = await context.$novulo.api.login({
        email,
        password,
        cartId
      });
      if (response.token) context.$cookies.set('N_user_token', response.token);
      else throw new Error('Server error');
    } catch (err) {
      Logger.error('useCurrentUser/login', err);
      throw err;
    }
  };

  const requestNewPassword = async ({ email }) => {
    return context.$novulo.api.requestNewPassword({ email });
  };

  return {
    getIsEmailRegistered,
    login,
    requestNewPassword
  };
};
