import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';
import type { Ref } from '@nuxtjs/composition-api';
import { useCurrentUser } from '../useCurrentUser';
import { useBloomreachEvents } from '../useBloomreachEvents';
import useUrlFormatter from '@vue-storefront/novulo-theme/hooks/useUrlFormatter';

type WishlistItem = {
  id: number;
  spli_id: number;
  name: string;
  thumbnail: string;
};

type ToggleResponse = {
  success: boolean;
  error: string | null;
  response: Record<string, any>;
  httpcode: number;
};

type UseWishlistType = {
  wishlist: Ref<WishlistItem[]>;
  getWishlist: () => Promise<void>;
  toggleFavorite: ({
    spli,
    sku,
    currency,
    browserDetails
  }) => Promise<ToggleResponse>;
};

export const useWishlist = (): UseWishlistType => {
  const context = useVSFContext();
  const { currentUser, getUserToken } = useCurrentUser();
  const { registerEvent } = useBloomreachEvents();
  const wishlist = sharedRef([], 'useWishlist-wishlist');
  const userToken = getUserToken();
  const formatUrl = useUrlFormatter();
  const getWishlist = async () => {
    if (!currentUser.value?.id) return;
    try {
      wishlist.value =
        (await context.$novulo.api.getWishlist({ userToken }))?.[0]
          ?.wishlist_items || [];
    } catch (e) {
      Logger.error('useWishlist/getWishlist', e);
    }
  };

  const toggleFavorite = async ({ spli, sku, currency, browserDetails }) => {
    if (!currentUser.value?.id) {
      const wishlistPath = formatUrl(context.i18n.t('/wishlist_url'));
      const loginUrl = formatUrl(context.i18n.t('login_url'));
      window.location.href = `${loginUrl}?ReturnUrl=${wishlistPath}?product=${sku}`;
      return;
    }

    const website_translation =
      parseInt(context?.$vsf.$novulo.config?.state?.getWt()) || 11;
    const itemIndex = wishlist.value.findIndex((item) => item.spli_id === spli);

    const apiSlug = itemIndex > -1 ? 'removeFromWishlist' : 'addToWishlist';

    const response = await context.$novulo.api[apiSlug]({
      website_user: currentUser.value.id,
      sales_price_list_item: spli,
      website_translation
    }).catch((e) => {
      Logger.error('useWishlist/toggleFavorite', e);
    });

    let updatedItem;
    let exponeaAction;
    if (itemIndex > -1) {
      updatedItem = wishlist.value[itemIndex];
      exponeaAction = 'remove';
      wishlist.value.splice(itemIndex, 1);
    } else {
      await getWishlist();
      updatedItem =
        wishlist.value.find((item) => item.spli_id === spli) ?? null;
      exponeaAction = 'add';
    }

    const { totalPrice, quantity, totalDiscount } = wishlist.value.reduce(
      (acc, item) => ({
        totalPrice: acc.totalPrice + item.item_sale_price,
        quantity: acc.quantity + 1,
        totalDiscount: acc.totalDiscount + item.discount
      }),
      { totalPrice: 0, quantity: 0, totalDiscount: 0 }
    );

    registerEvent('wishlist_update', {
      ...browserDetails,
      action: exponeaAction,
      total_quantity: quantity,
      total_price: totalPrice,
      total_discount_value: totalDiscount,
      currency,
      last_updated_item_id: updatedItem.sku,
      item_ids: wishlist.value.map((item) => ({
        item_id: item.sku,
        qty: 1
      })),
      item_details: wishlist.value
        .sort((a, b) => {
          if (a.spli_id === spli) return -1;
          if (b.spli_id === spli) return 1;
          return 0;
        })
        // TODO: maybe add domain to url
        .map((item) => ({
          item_id: item.sku,
          brand: item.brand,
          category: item.category,
          url: item.url,
          title: item.name,
          item_original_price: item.item_original_price,
          item_sale_price: item.item_sale_price,
          quantity: 1,
          total_price: item.item_sale_price,
          total_discount: item.discount
        }))
    });

    return response;
  };

  return {
    wishlist,
    getWishlist,
    toggleFavorite
  };
};
