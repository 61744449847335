import { computed } from '@nuxtjs/composition-api';
import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';
export const useMinimalHeaderFooter = () => {
  const context = useVSFContext();
  const minimalHeaderFooterResult = sharedRef(
    null,
    'useMinimalHeaderFooter/minimalHeaderFooterResult'
  );
  const loading = sharedRef(false, 'useMinimalHeaderFooter-loading');
  const error = sharedRef(null, 'useMinimalHeaderFooter-error');

  const getMinimalHeaderFooter = async () => {
    if (minimalHeaderFooterResult.value) return;

    try {
      loading.value = true;

      minimalHeaderFooterResult.value =
        await context.$novulo.api.getMinimalHeaderFooter();
    } catch (err) {
      error.value = err;
      Logger.error('useFooter', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    getMinimalHeaderFooter,
    minimalHeaderFooterResult: computed(() => minimalHeaderFooterResult.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value)
  };
};
