import { computed } from '@nuxtjs/composition-api';
import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';

export const useHeader = () => {
  const context = useVSFContext();
  const result = sharedRef(null, "useHeader");
  const loading = sharedRef(false, "useHeader-loading");
  const error = sharedRef({
    getPDP: null
  }, "useHeader-error");

  const getHeader = async () => {
    Logger.debug('useHeader custom componsable');

    try {
      loading.value = true;
      result.value = await context.$novulo.api.getHeader();
    } catch (err) {
      result.value = [];
      error.value.getHeader = err;
      Logger.error("useHeader", err);
    } finally {
      loading.value = false;
    }
  }

  return {
    getHeader,
    headerResult: computed(() => result.value),
    headerLoading: computed(() => loading.value),
    headerError: computed(() => error.value)
  };
};
