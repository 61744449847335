import { computed, ComputedRef } from '@nuxtjs/composition-api';
import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';
import { useCurrentUser } from '../useCurrentUser';
import type { UserAddress } from '@vue-storefront/novulo-api';
import { useAddressForm } from '../useAddressForm';

const CART_COOKIE = 'n_shopping_cart';
export const useUserAddresses = (): {
  userAddresses: ComputedRef<UserAddress[] | null>;
  userFullname: ComputedRef<string>;
  loading: any;
  error: any;
  getUserAddresses: () => Promise<void>;
  addEditAddress: (addressId: number | undefined) => Promise<void>;
} => {
  const context = useVSFContext();
  const { getCurrentUser, currentUser, getUserToken } = useCurrentUser();
  const { model } = useAddressForm();
  const userFullname = sharedRef('', 'useUserAddresses-userFullName');
  const userAddresses = sharedRef([], 'useUserAddresses-userAddress');
  const loading = sharedRef(false, 'useUserAddresses-loading');
  const error = sharedRef(
    {
      getUserAddresses: null,
      getGuestUserAddresses: null
    },
    'useUserAddresses-error'
  );

  const getUserAddresses = async () => {
    loading.value = true;
    let response = [];

    if (!currentUser.value?.contact?.contact_id) await getCurrentUser();

    if (!currentUser.value?.contact?.contact_id) {
      const cartId = context.$cookies.get(CART_COOKIE) || null;
      if (!cartId) return;

      response = <UserAddress[]>await context.$novulo.api
        .getGuestUserAddresses(cartId)
        .catch((e) => {
          error.value.getGuestUserAddresses = e;
          Logger.error('useUserAddresses-getGuestUserAddresses', e);
        })
        .finally(() => {
          loading.value = false;
        });

      userFullname.value = `${response[0]?.first_name} ${response[0]?.surname}`;
    } else {
      response = <UserAddress[]>await context.$novulo.api
        .getUserAddresses(currentUser.value?.contact?.contact_id)
        .catch((e) => {
          error.value.getUserAddresses = e;
          Logger.error('useUserAddresses-getUserAddresses', e);
        })
        .finally(() => {
          loading.value = false;
        });

      userFullname.value = `${currentUser.value?.contact?.first_name} ${currentUser.value?.contact?.full_surname}`;
    }

    userAddresses.value = response;
  };

  const getAddressFormParams = () => ({
    street: model.value.street,
    house_number: parseInt(model.value.house_number),
    house_number_appendix: model.value.house_number_appendix,
    city: model.value.city,
    country_iso_code: model.value.country.iso_code,
    is_default_billing_address: model.value.is_default_invoice_address,
    is_default_delivery_address: model.value.is_default_delivery_address,
    additional_address_line: model.value.additional_address_line,
    postal_code: model.value.postal_code
  });

  const addEditAddress = async (addressId: undefined) => {
    loading.value = true;

    const addressFormParams = getAddressFormParams();
    const params = {
      ...addressFormParams,
      address: addressId || undefined,
      user_token: getUserToken(),
      cms_user: currentUser.value?.id
    };

    await context.$novulo.api
      .addEditAddress(params)
      .then(async () => await getUserAddresses())
      .catch((e) => Logger.error('useUserAddressses/addEditAddress', e))
      .finally(() => (loading.value = false));
  };

  return {
    getUserAddresses,
    addEditAddress,
    userAddresses: computed(() => userAddresses.value),
    userFullname: computed(() => userFullname.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value)
  };
};
