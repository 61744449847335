import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';
import type { Ref } from '@nuxtjs/composition-api';

type Translation = {
  code: string;
  translation: string;
};

type UseTranslationsType = {
  translate: (code: string) => string;
  getTranslations: () => Promise<void>;
};

export const useTranslations = (): UseTranslationsType => {
  const context = useVSFContext();

  const translations: Ref<Translation[]> = sharedRef(
    [],
    'useTranslations-translations'
  );

  const getTranslations = async () => {
    try {
      if (!translations.value.length) {
        translations.value = await context.$novulo.api.getTranslations();
      }
    } catch (e) {
      Logger.error('useTranslations/getTranslations', e);
    }
  };

  const translate = (code) =>
    translations.value.find((translation) => code === translation.code)
      ?.translation || `${code}^`;

  return {
    translate,
    getTranslations
  };
};
