export const createBloomreachCartUpdateEventDetails = ({
  cartId,
  cartItems,
  cartItem,
  oldQuantity,
  newQuantity,
  currency,
  browserDetails
}) => {
  if (!cartItem) return;
  let action;
  if (newQuantity === 0) action = 'remove';
  else if (oldQuantity === 0) action = 'add';
  else action = 'update';
  const { totalQuantity, totalPrice, totalDiscount } = cartItems.reduce(
    (acc, item) => ({
      totalQuantity: acc.totalQuantity + item.quantity,
      totalPrice: acc.totalPrice + item.price,
      totalDiscount: acc.totalDiscount + item.discount
    }),
    { totalQuantity: 0, totalPrice: 0, totalDiscount: 0 }
  );
  const sortedCart = cartItems.sort((a, b) => {
    if (a.id === cartItem.id) return -1;
    if (b.id === cartItem.id) return 1;
    return 0;
  });
  return {
    ...browserDetails,
    action,
    cart_id: cartId,
    total_quantity: totalQuantity,
    total_price: totalPrice,
    total_discount_value: totalDiscount,
    currency,
    last_updated_item_id: cartItem.sku,
    last_updated_item_title: cartItem.name,
    last_updated_item_brand: cartItem.brand,
    last_updated_item_category: cartItem.category,
    item_ids: sortedCart.map((item) => ({
      item_id: item.sku,
      qty: item.quantity
    })),
    // TODO: maybe add host to url
    item_details: sortedCart.map((item) => ({
      item_id: item.sku,
      brand: item.brand,
      category: item.category,
      url: item.url,
      title: item.name,
      item_original_price: (item.price + item.discount) / item.quantity,
      item_sale_price: item.price / item.quantity,
      discount: item.discount / item.quantity,
      quantity: item.quantity,
      total_price: item.price,
      total_discount: item.discount
    }))
  };
};
