import { useVSFContext } from '@vue-storefront/core';

const PREFERRED_DELIVERY_ADDRESS_COOKIE = 'preferred-delivery-address';
const PREFERRED_INVOICE_ADDRESS_COOKIE = 'preferred-invoice-address';
const PREFERRED_PAYMENT_METHOD_COOKIE = 'preferred-payment-method';

export const useCheckoutPreferences = () => {
  const context = useVSFContext();

  const getPreferredDeliveryAddressId = () => {
    const preferredDeliveryAddressId = context.$cookies.get(
      PREFERRED_DELIVERY_ADDRESS_COOKIE
    );
    if (preferredDeliveryAddressId) return parseInt(preferredDeliveryAddressId);
    else return null;
  };
  const setPreferredDeliveryAddressId = (value: number) => {
    context.$cookies.set(PREFERRED_DELIVERY_ADDRESS_COOKIE, String(value));
  };

  const getPreferredInvoiceAddressId = () => {
    const preferredInvoiceAddressId = context.$cookies.get(
      PREFERRED_INVOICE_ADDRESS_COOKIE
    );
    if (preferredInvoiceAddressId) return parseInt(preferredInvoiceAddressId);
    else return null;
  };
  const setPreferredInvoiceAddressId = (value: number) => {
    context.$cookies.set(PREFERRED_INVOICE_ADDRESS_COOKIE, String(value));
  };

  const getPreferredPaymentMethodCode = () => {
    return context.$cookies.get(PREFERRED_PAYMENT_METHOD_COOKIE) || null;
  };
  const setPreferredPaymentMethodCode = (value: string) => {
    context.$cookies.set(PREFERRED_PAYMENT_METHOD_COOKIE, value);
  };

  return {
    getPreferredDeliveryAddressId,
    setPreferredDeliveryAddressId,
    getPreferredInvoiceAddressId,
    setPreferredInvoiceAddressId,
    getPreferredPaymentMethodCode,
    setPreferredPaymentMethodCode
  };
};
