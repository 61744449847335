import { computed } from '@nuxtjs/composition-api';
import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';

export const useMegaMenu = () => {
  const context = useVSFContext();
  const megaMenuResult = sharedRef(null, 'useMegaMenu');
  const loading = sharedRef(false, 'useMegaMenu-loading');
  const error = sharedRef(
    {
      getMegaMenu: null
    },
    'useMegaMenu-error'
  );

  const getMegaMenu = async () => {
    Logger.debug('useMegaMenu custom componsable');

    try {
      loading.value = true;
      megaMenuResult.value = await context.$novulo.api.getMegaMenu();
    } catch (err) {
      error.value.getMegaMenu = err;
      Logger.error('useMegaMenu', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    getMegaMenu,
    megaMenuResult: computed(() => megaMenuResult.value),
    megaMenuLoading: computed(() => loading.value),
    megaMenuError: computed(() => error.value)
  };
};
