import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';
import { computed } from '@nuxtjs/composition-api';
import { CmsContainerType } from '@vue-storefront/novulo-api';

export const useCustomCMSPage = () => {
  const containers = sharedRef<CmsContainerType[]>(
    [],
    'useCustomCMSPage-containers'
  );
  const error = sharedRef(null, 'useCustomCMSPage-error');
  const isLoading = sharedRef(false, 'useCustomCMSPage-isLoading');

  const context = useVSFContext();

  const getCustomCMSPage = async (endpointURL: string) => {
    try {
      error.value = null;
      isLoading.value = true;
      const data = await context.$novulo.api.getCustomCMSPage(endpointURL);
      containers.value = data?.containers || [];
    } catch (err) {
      error.value = err;
      Logger.error('useCustomCMSPage/getCustomCMSPage', err);
    } finally {
      isLoading.value = false;
    }
  };

  return {
    containers: computed(() => containers.value),
    error: computed(() => error.value),
    isLoading: computed(() => isLoading.value),
    getCustomCMSPage
  };
};
