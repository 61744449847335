import { computed } from '@nuxtjs/composition-api';
import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';
import type { WriteReviewData as ApiWriteReviewData } from '@vue-storefront/novulo-api';

type WriteReviewData = Omit<ApiWriteReviewData, 'userId'>;

export const useReview = () => {
  const context = useVSFContext();
  const result = sharedRef(null, 'useReview');
  const writeReviewEssentials = sharedRef(
    null,
    'useReview-writeReviewEssentials'
  );
  const loading = sharedRef(false, 'useReview-loading');
  const error = sharedRef(
    {
      getReviews: null
    },
    'useReview-error'
  );

  const getReviews = async (params) => {
    Logger.debug('useReview custom componsable');
    try {
      loading.value = true;
      result.value = await context.$novulo.api.getReviews(params);
    } catch (err) {
      error.value.getReviews = err;
      Logger.error('useReview', err);
    } finally {
      loading.value = false;
    }
  };

  const getWriteReviewEssentials = async (productId: number) => {
    Logger.debug('useReview custom componsable');
    try {
      const response = await context.$novulo.api.getWriteReviewEssentials(
        productId
      );
      if (!response?.success)
        throw new Error('getWriteReviewEssentials failed');
      writeReviewEssentials.value = response.response;
    } catch (err) {
      Logger.error('useReview', err);
    }
  };

  const writeReview = (data: WriteReviewData) => {
    return context.$novulo.api.writeReview(data);
  };

  return {
    getReviews,
    getWriteReviewEssentials,
    writeReview,
    reviewResult: computed(() => result.value),
    writeReviewEssentials: computed(() => writeReviewEssentials.value),
    reviewLoading: computed(() => loading.value),
    reviewError: computed(() => error.value)
  };
};
