import { computed, ComputedRef } from '@nuxtjs/composition-api';
import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';

const USER_COOKIE = 'N_user_token';
const USERNAME_COOKIE = 'n_user_name';

type CurrentUser = {
  id: string;
  email: string;
  contact: {
    contact_id: number;
    full_surname: string;
    first_name: string;
    company: string;
    id?: number;
  };
};

export const useCurrentUser = (): {
  currentUser: ComputedRef<CurrentUser | null>;
  loading: any;
  error: any;
  getCurrentUser: () => Promise<void>;
  getUserToken: () => string | null;
} => {
  const context = useVSFContext();

  const getUsernameCookie = (): string => {
    const username = context.$cookies.get(USERNAME_COOKIE) || '';
    return username;
  };

  const createInitialCurrentUser = (): CurrentUser | null => {
    const username = getUsernameCookie();
    if (username) {
      return {
        id: '',
        email: '',
        contact: {
          contact_id: null,
          first_name: username,
          full_surname: '',
          company: null,
          id: null
        }
      };
    }
    return null;
  };

  const currentUser = sharedRef<CurrentUser | null>(
    createInitialCurrentUser(),
    'useCurrentUser-currentUser'
  );

  const setCurrentUser = (user: CurrentUser) => {
    const username = user?.contact?.first_name || '';
    if (username) {
      context.$cookies.set(USERNAME_COOKIE, username);
    } else {
      context.$cookies.remove(USERNAME_COOKIE);
    }
    currentUser.value = user;
  };

  const loading = sharedRef(
    {
      getCurrentUser: false
    },
    'useCurrentUser-loading'
  );
  const error = sharedRef(
    {
      getCurrentUser: null
    },
    'useCurrentUser-error'
  );

  const getUserToken = (): string | null =>
    context.$cookies.get(USER_COOKIE) || null;

  const getCurrentUser = async () => {
    const userToken = getUserToken();
    Logger.debug('useCurrentUser getCurrentUser custom componsable', userToken);
    if (!userToken) {
      setCurrentUser(null);
      return;
    }
    try {
      loading.value.getCurrentUser = true;
      const response = await context.$novulo.api.getCurrentUser(userToken);
      if (!response || response.length === 0) {
        throw new Error('No user found');
      } else {
        const data = response[0];
        setCurrentUser(data);
      }
    } catch (err) {
      context.$cookies.remove(USER_COOKIE);
      setCurrentUser(null);
      error.value.getCurrentUser = err;
      Logger.error('useCurrentUser/getCurrentUser', err);
    } finally {
      loading.value.getCurrentUser = false;
    }
  };

  return {
    currentUser: computed<CurrentUser | null>(() => currentUser.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    getCurrentUser,
    getUserToken
  };
};
