import type {
  RegisterResponse,
  RegisterParams
} from '@vue-storefront/novulo-api';

import { useVSFContext, sharedRef, Logger } from '@vue-storefront/core';
import type { UseRegistration } from './interfaces';
import { computed, Ref } from '@nuxtjs/composition-api';
import { useAddressForm } from '../useAddressForm';
import { useCheckoutCart } from '../useCheckoutCart';

export const useRegistration = (): UseRegistration => {
  const context = useVSFContext();
  const { model } = useAddressForm();
  const { cartId } = useCheckoutCart();

  const loading = sharedRef(false, 'useRegistration-loading');

  const registrationResult: Ref<RegisterResponse> = sharedRef(
    null,
    'useRegistration-registrationResult'
  );

  const register = async () => {
    loading.value = true;

    let houseAdditionalLine = model.value.additional_address_line;
    let houseAppendix = '';
    const houseNumber = parseInt(model.value.house_number);
    if (!houseNumber && !houseAdditionalLine) {
      houseAdditionalLine = model.value.house_number_appendix;
    } else {
      houseAppendix = model.value.house_number_appendix;
    }

    const params: RegisterParams = {
      cart_guid: cartId,
      contact_email: model.value.email,
      contact_first_name: model.value.first_name,
      contact_surname: model.value.surname,
      contact_telephone: model.value.phone_number,
      contact_company_name: model.value.organisation || '',
      invoice_address_street: model.value.street,
      invoice_address_housenr: houseNumber,
      invoice_address_housenr_appendix: houseAppendix,
      invoice_address_additional_line: houseAdditionalLine,
      invoice_address_postal_code: model.value.postal_code,
      invoice_address_city: model.value.city,
      invoice_address_country_code_iso: model.value.country.iso_code,
      invoice_address_is_delivery_address: true,
      delivery_address_street: model.value.street,
      delivery_address_housenr: houseNumber,
      delivery_address_housenr_appendix: houseAppendix,
      delivery_address_additional_line: houseAdditionalLine,
      delivery_address_postal_code: model.value.postal_code,
      delivery_address_city: model.value.city,
      delivery_address_country_code_iso: model.value.country.iso_code,
      vat_number: model.value.vat_number || undefined,
      password: model.value.password || undefined,
      invoice_address_supplement: model.value.address_supplement,
      newsletter_opt_in: model.value.newsletter_opt_in,
      newsletter_opt_in_soft: model.value.newsletter_opt_in_soft,
      contact_surname_prefix: model.value.prefix_surname,
      create_new_user: model.value.create_new_user
    };

    registrationResult.value = await context.$novulo.api
      .register(params)
      .then((result) => {
        if (result.response.token)
          context.$cookies.set('N_user_token', result.response.token);

        return result;
      })
      .catch((e) => Logger.error('useRegistration/register', e))
      .finally(() => (loading.value = false));
  };

  const validateVat = async (): Promise<{ is_valid: boolean }> => {
    loading.value = true;

    const { response } = await context.$novulo.api
      .validateVat(model.value.vat_number)
      .catch((e) => Logger.error('useRegistration/validateVat', e))
      .finally(() => (loading.value = false));

    return response;
  };

  return {
    loading,
    registrationResult: computed(() => registrationResult.value),
    register,
    validateVat
  };
};
