import { useContext } from '@nuxtjs/composition-api';

const URISchemes = [
  'https://',
  'http://',
  'tel:',
  'mailto:',
  'data:',
  'ftp:',
  'file:'
];

function isFullUrl(url: string): boolean {
  return URISchemes.some((scheme) => url.startsWith(scheme));
}

export default function useUrlFormatter(): (
  url: string,
  options?: { default: string | null }
) => string {
  const { i18n } = useContext() as any;

  return (
    url?: string,
    options: { default: string | null } = { default: '#' }
  ): string => {
    if (!url) return options.default;
    if (isFullUrl(url)) return url;
    const urlWithSlash = url.startsWith('/') ? url : `/${url}`;
    const localePrefix =
      i18n.locale === i18n.defaultLocale ? '' : `/${i18n.locale}`;
    if (urlWithSlash.startsWith(localePrefix)) return urlWithSlash;
    return `${localePrefix}${urlWithSlash}`;
  };
}
