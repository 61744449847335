import { Logger, useVSFContext } from '@vue-storefront/core';

type GetSlugRedirectsType = (
  slug: string,
  host: string
) => Promise<Record<string, string>>;

export const useLanguageSelection = (): {
  getSlugRedirects: GetSlugRedirectsType;
} => {
  const context = useVSFContext();

  const getSlugRedirects: GetSlugRedirectsType = async (slug, host) => {
    Logger.debug('useLanguageSelection/getSlugRedirects called');
    return context.$novulo.api.getSlugRedirects(slug, host);
  };

  return {
    getSlugRedirects
  };
};
