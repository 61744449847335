import { computed } from '@nuxtjs/composition-api';
import { Logger, sharedRef, useVSFContext } from '@vue-storefront/core';

export const usePLPEssentials = () => {
  const context = useVSFContext();
  const result = sharedRef(null, 'usePLPEssentials');
  const loading = sharedRef(false, 'usePLPEssentials-loading');
  const error = sharedRef(
    {
      getPLPEssentials: null
    },
    'usePLPEssentials-error'
  );

  const getPLPEssentials = async (params) => {
    Logger.debug('usePLPEssentials custom componsable');
    try {
      loading.value = true;
      result.value = await context.$novulo.api.getPLPEssentials(params);
    } catch (err) {
      error.value.getPLPEssentials = err;
      Logger.error('usePLPEssentials', err);
    } finally {
      loading.value = false;
    }
  };

  return {
    getPLPEssentials,
    PLPEssentialsResult: computed(() => result.value),
    PLPEssentialsLoading: computed(() => loading.value),
    PLPEssentialsError: computed(() => error.value)
  };
};
